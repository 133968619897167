import FormProvider from 'components/ui/forms/FormProvider';
import { MEDIUM_INPUT_WIDTH } from 'configs/layout';
import { useFormik } from 'formik';
import useDI from 'hooks/useDI';
import React from 'react';
import * as Yup from 'yup';
import { FormUtils } from 'utils/Form';
import { Stack } from '@mui/material';
import DeleteButton from 'components/ui/buttons/DeleteButton';

import PhoneInputMemorized from 'components/ui/forms/PhoneInputMemorized';

type Props = {
  formId: string;
  formsStatesMapRef: React.MutableRefObject<FormUtils.ChildFormsMap>;
  rerenderParent: VoidFunction;
  initialState?: Phone;
};

function EditPhoneItemForm({ formId, formsStatesMapRef, rerenderParent, initialState }: Props) {
  const { services, statefulUtils } = useDI();
  const { translate } = services.language;

  const fieldIsRequiredText = translate('errors.fieldIsRequired');
  const phoneValidation = statefulUtils.phone.validate;
  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .required(fieldIsRequiredText)
      .test({
        message: translate('errors.invalidPhoneFormat'),
        test: phoneValidation,
      }),
  });

  const initialValues = {
    phone: initialState?.number || '',
  };

  const formState = useFormik({
    initialValues,
    validationSchema,

    onSubmit: async (values): Promise<string> => {
      return statefulUtils.phone.getRFC3966(values.phone);
    },
  });

  formsStatesMapRef.current.set(formId, formState);
  const itemsLength = Array.from(formsStatesMapRef.current.keys()).length;

  return (
    <Stack direction="row" spacing={1} alignItems="flex-start">
      <FormProvider formState={formState} containerSx={{ width: { xs: '100%', md: MEDIUM_INPUT_WIDTH } }}>
        <PhoneInputMemorized
          label={translate('entities.order.clientsPhone')}
          fieldName="phone"
          formState={formState}
          defaultCountry={initialState?.country}
        />
      </FormProvider>

      {itemsLength > 1 && (
        <DeleteButton onClick={FormUtils.deleteChildFormHandlerFactory(formId, formsStatesMapRef.current, rerenderParent)} />
      )}
    </Stack>
  );
}

export default React.memo(EditPhoneItemForm, () => true);
